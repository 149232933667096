/** @jsx jsx */
import { Box, Grid, jsx, Flex, Input, Label } from "theme-ui"
import { darken, lighten } from "@theme-ui/color"
import React, { useRef, useState, useEffect } from "react"
import {
  useDispatchCurrentUser,
  useCurrentUser,
} from "../components/CurrentUser"
import { callApi, callApiNoAuth } from "../utils"
import { navigate, Link } from "gatsby"
import LoginLayout from "../components/loginLayout"
import LoginLogo from "../components/LoginLogo"

const SignUpPageClosed = ({ location }) => {
  const emailRef = useRef()
  const passwordRef = useRef()

  const [errorMsg, setErrorMsg] = useState(null)
  const [successMsg, setSuccessMsg] = useState(null)
  const dispatch = useDispatchCurrentUser()
  let currentUser = useCurrentUser()

  useEffect(() => {
    if (currentUser.isAuthenticated) {
      navigate("/app")
    }
    // console.log("currentUser", currentUser)
  }, [currentUser])

  const handleSubmit = async e => {
    e.preventDefault()
    setErrorMsg(null)
    try {
      const response = await callApiNoAuth("/auth/local/register", "POST", {
        username: emailRef.current.value,
        email: emailRef.current.value,
        password: passwordRef.current.value,
      })

      if (response.user) {
        setSuccessMsg(
          "New user successfully created.  You will receive a confirmation email shortly with instructions."
        )
      } else {
        const message = `An error has occured:${response?.message?.messages}`
        setErrorMsg(message)
        //throw new Error(message)
      }
    } catch (err) {
      setErrorMsg(err)
    }
  }

  return (
    <LoginLayout>
      <div sx={styles}>
        <LoginLogo />

        <Box className="signUpPage">
          <h1>Sign Up</h1>
          {/* {successMsg ? (
            <div sx={{ "& p.success": { color: "red" } }}>
              {successMsg && <p className="success">{successMsg}</p>}
            </div>
          ) : (
            <Box as="form" onSubmit={handleSubmit}>
              <fieldset id="password-reset" sx={{ border: "none", py: 3 }}>
                <p>Please fill out the form below to create a new user.</p>

                <div>
                  <Label htmlFor="email-address">Email</Label>
                  <Input
                    ref={emailRef}
                    type="email"
                    name="email-address"
                    id="email-address"
                  />
                </div>
                <div>
                  <Label htmlFor="password">Password</Label>
                  <Input
                    ref={passwordRef}
                    type="password"
                    name="password"
                    id="password"
                  />
                </div>
              </fieldset>
              <div>
                <Input type="submit" value="Submit" />
              </div>
              <div sx={{ "& p.error": { color: "red" } }}>
                {errorMsg && <p className="error">{errorMsg}</p>}
              </div>
            </Box>
          )} */}
          <Box>
            <p>
              Membership signup is currently disabled while we onboard previous
              members. We are expecting to complete this in the next week.
            </p>
            <p>
              If you are a current member you will receive email instructions to
              onboard you to the new system in due course.
            </p>
            <p>
              If you have a membership request in the meantime please email{" "}
              <a href="mailto:info@masterclass.org.uk">
                info@masterclass.org.uk
              </a>
              .
            </p>
          </Box>

          <div>
            <Link to="/login">Back To Login</Link>
          </div>
        </Box>
      </div>
    </LoginLayout>
  )
}

export default SignUpPageClosed

const styles = {
  "& .signUpPage": {
    margin: "0 auto",
    maxWidth: "50%",
    background: "white",
    padding: 4,
    "& p.error": {
      color: "red",
    },
    "input[type=submit]": {
      bg: "accent",
      color: "white",
      cursor: "pointer",
      transition: "background 0.3s ease-in-out",
      "&:hover": {
        background: darken("accent", 0.1),
      },
    },
    "input[type=text]:focus-visible": {
      outline: "red",
    },
    a: {
      color: "accent",
    },
    ".otherLinks": {
      margin: "0 auto",
      mt: 3,
      textAlign: "center",
      a: {
        pr: 3,
      },
    },
  },
  "& .error": {
    color: "red",
  },
}
